// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        'html > body': {
          backgroundColor: '#333E4F',
        },
        '#__next': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        ':root': {
          // 首页的 banner swiper pagination 样式定义
          // '--swiper-pagination-bullet-width': '12px',
          // '--swiper-pagination-bullet-height': '4px',
          // '--swiper-pagination-bullet-border-radius': '2px',
          '--swiper-pagination-color': '#ffffff',
          // '--swiper-pagination-top': '100px',
          '--swiper-pagination-bullet-inactive-color': '#ffffff',
        },

        ':root .home-dailog-banner-swiper': {
          '--swiper-pagination-bullet-width': '8px',
          '--swiper-pagination-bullet-height': '8px',
        },

        '.home-dailog-banner-swiper .swiper-pagination-bullet-active': {
          width: '36px',
          borderRadius: '4px',
        },
      }}
    />
  );

  return inputGlobalStyles;
}
