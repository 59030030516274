import CommonSkeleton from '@/components/CommonSkeleton';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import AuthTabs from '@/pages/Auth/components/AuthTabs';
import services from '@/services';
import {
  isLoginState,
  isOpenLRModalState,
  LRModalInitalPage,
} from '@/stores/auth';
import { globalMaxWidth } from '@/theme';
import { SigninType } from '@/types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, Drawer, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import LoginForm from '../login/LoginForm';
import RegisterForm from '../register/RegisterForm';

const styles = {
  titleBtnBg: {
    width: 120,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

interface Props {
  initialPage?: number;
}

/**
 * 登录注册合一弹窗
 *
 * @return {*}
 */
const LRModal: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = useRecoilState(isOpenLRModalState);
  const [initialPage, setInitalPage] = useRecoilState(LRModalInitalPage);
  const [isActive, setIsActive] = useState<number>(initialPage || 0);
  const [isLoading, setIsLoading] = useBoolean(true);
  const search = new URLSearchParams(window.location.search);
  const guid = search.get('guid');
  const id = search.get('id');
  const navigate = useNavigate();
  const { login, getUserInfo, getWallet } = useAuth();
  const setIsLogin = useSetRecoilState(isLoginState);
  const { __ } = useLang();
  const isMobile = useResponsive('down', 'md');
  const rememberAccountP = localStorage.getItem('rememberAccount') || '{}';
  const rAccountJson = JSON.parse(rememberAccountP);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsActive(initialPage);
    if (isOpen && guid && id) {
      fetchOauthGuid({ guid, id });
    } else {
      setIsLoading.off();
    }
    // 有记住账号密码，自动登录
    if (
      isOpen &&
      rAccountJson?.password &&
      (rAccountJson?.phone || rAccountJson?.email) &&
      rAccountJson?.type
    ) {
      handleLogin();
    }
  }, [guid, id, isOpen]);

  const fetchOauthGuid = async (params: { guid: string; id: string }) => {
    const res = await services.auth.getOauthGuid({
      ...params,
      type: 1,
    });
    console.log(res);
    if (res?.data?.token) {
      // TODO 登录
      login(res?.data);
    } else if (res?.data?.is_bind === 2 || res?.data?.is_binding === 2) {
      setIsLoading.off();
      SnackbarUtils.info(__('您还未绑定过账号，请先注册并自动绑定'));
      navigate('/signup?guid=' + guid + '&id=' + id);
    } else {
      // 其他错误
      setIsLoading.off();
    }
  };

  const handleLogin = async () => {
    setIsLoading.on();
    try {
      let params: any = rAccountJson;

      const res = await services.auth.login(params);
      const token = res.data?.data?.token;
      if (token) {
        localStorage.setItem('access_token', token);
        setIsLogin(true);
        SnackbarUtils.success('Login success');
        getUserInfo();
        getWallet();
        navigate('/');
        setIsOpen(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading.off();
    }
  };

  const HeaderView = (
    <Box
      position="relative"
      height={56}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Button
        variant="text"
        sx={[
          styles.titleBtnBg,
          { color: isActive == 0 ? 'primary.main' : 'text.disabled' },
        ]}
        onClick={() => {
          setIsActive(0);
        }}
      >
        {__('login')}
      </Button>
      <Button
        variant="text"
        sx={[
          styles.titleBtnBg,
          { color: isActive == 1 ? 'primary.main' : 'text.disabled' },
        ]}
        onClick={() => {
          setIsActive(1);
        }}
      >
        {__('register')}
      </Button>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: 56,
          height: 56,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton color="info" onClick={handleClose}>
          <CloseIcon sx={{ fontSize: 24, color: '#8B899C' }} />
        </IconButton>
      </Box>
    </Box>
  );

  const LoginConten = (
    <Box px={2} pb={0.5}>
      <AuthTabs
        emailTab={
          <LoginForm
            id={id || ''}
            guid={guid || ''}
            type={SigninType.Email}
            displayType="dialog"
          />
        }
        phoneTab={
          <LoginForm
            id={id || ''}
            guid={guid || ''}
            type={SigninType.Phone}
            displayType="dialog"
          />
        }
      />
    </Box>
  );

  const RegisterConten = (
    <Box px={2} pb={0.5}>
      <AuthTabs
        emailTab={<RegisterForm type={SigninType.Email} displayType="dialog" />}
        phoneTab={<RegisterForm type={SigninType.Phone} displayType="dialog" />}
      />
    </Box>
  );

  return (
    <Drawer
      anchor={'bottom'}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      ModalProps={{
        container: document.getElementById('root') as HTMLElement,
      }}
      PaperProps={{
        sx: {
          borderRadius: '24px 24px 0 0',
          width: '100%',
          maxWidth: globalMaxWidth,
          left: {
            xs: 0,
            sm: `calc(50% - ${globalMaxWidth / 2}px)`,
          },
        },
      }}
    >
      <Box
        bgcolor="transparent"
        position="relative"
        style={{
          width: '100%',
          height: '85vh',
          overflowY: 'hidden',
        }}
        sx={{ bgcolor: 'card.main' }}
      >
        {isLoading ? (
          <CommonSkeleton />
        ) : (
          <>
            {HeaderView}
            <Divider sx={{ mb: 1 }} />
            {isActive == 0 ? LoginConten : RegisterConten}
          </>
        )}
      </Box>
    </Drawer>
  );
  // if (isMobile) {
  // }
  // return (
  //   <Dialog
  //     open={isOpen}
  //     PaperProps={{
  //       sx: {
  //         width: '400px',
  //         maxWidth: '400px',
  //       },
  //     }}
  //     onClose={() => setIsOpen(false)}
  //     aria-labelledby="sign-in"
  //   >
  //     <Box sx={{ flex: 1 }} bgcolor="background.neutral">
  //       {isLoading ? (
  //         <CommonSkeleton />
  //       ) : (
  //         <>
  //           {HeaderView}
  //           <Divider sx={{ mb: 1 }} />
  //           {isActive == 0 ? LoginConten : RegisterConten}
  //         </>
  //       )}
  //     </Box>
  //   </Dialog>
  // );
};

export default LRModal;
